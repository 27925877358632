<template>
  <div id="Tienda">
    <HeaderMenu />
    <div class="head-nav-cont">
      <div class="nav-product">
        <div class="nav-pro-cont">
          <input type="checkbox" name="check" id="checknav" />
          <label for="checknav" class="label1">
            <div class="nav-btn">
              <p>Categoría</p>
              <img src="../assets/arrow2.svg" alt="arrow" />
            </div>
          </label>
          <div class="nav-options">
            <a href="/tienda/bovinos">Bovinos</a>
            <a href="/tienda/equinos">Equinos</a>
            <a href="/tienda/mascotas">Mascotas</a>
            <!--<a href="/tienda/">Ovinos</a>
            <a href="/tienda/">Cerdos</a>-->
            <a href="/tienda/aves">Aves</a>
            <a href="/tienda/agricola">Agrícola</a>
            <a href="/tienda/ferreteria">Ferretería</a>
            <!--<a href="/tienda/">Otros</a>-->
          </div>
          <div class="nav-free-send" v-if="validText(gp)"> 
            <img src="../assets/trailer.svg" alt="trailer" />
            <p>{{gp[0].texto}} </p>
          </div>
        </div>
      </div>
      <div class="header-prod  pc" :style="{'background-image':'url('+isWebp(require('../assets/banner1.webp'), require('../assets/banner1.png'))+')'}"></div>
      <div class="header-prod mo " :style="{'background-image':'url('+isWebp(require('../assets/banner2.webp'), require('../assets/banner2.png'))+')'}"></div>
    </div>

    <div class="tienda-body">
      <div
        class="header-tienda"
        :style="{
          'background-image': 'url(' + isWebp(require('../assets/vacas-b.webp'), require('../assets/vacas-b.png')) + ')',
        }"
      >
        {{ this.$route.params.name }}
      </div>
      <div class="tienda-cont" v-if="cates !== 'No se encontraron coincidencias'">
        <div
          class="tienda-cont-el"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="1000"
          :data-aos-delay="200"
        >
          <div class="category-header">
            <p
              v-for="(cat, i) in cates"
              :key="i"
              @click="catSelected = i"
              :class="{ realed: catSelected == i }"
            >
              <label for="catsel"></label>
              {{ cat.name }}
            </p>
          </div>
          <div
            class="products-cont"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1000"
            :data-aos-delay="400"
          >
            <div class="product-info" v-for="(shop, i) in data2" :key="i">
              <a class="centeredInfo" :href="'/producto/' + shop.url">
                <!--v-if="shop.categoria == catSelected"-->
                <div class="product-img">
                  <img
                    :src="
                      urlpath.url() +
                      '/P6H_3Pm/bt7-EO4/get-product-image/' +
                      isWebp(regexImg(shop.image), shop.image)
                    "
                    alt="img"
                  />
                </div>
                <p class="titulo-product">{{ shop.name }}</p>
                <p class="price-product">${{ shop.price }}MXN</p>
                <a :href="shop.link" class="btn-comprar">
                  <img src="../assets/carrito-w.svg" alt="cart" class="cart" />
                  <p>COMPRAR</p>
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="tienda-cont" v-else-if="cates == 'No se encontraron coincidencias'">
        <div
          class="tienda-cont-el"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="1000"
          :data-aos-delay="200"
        >
          <div
            class="products-cont"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="1000"
            :data-aos-delay="400"
            v-if="data != 'No se encontraron coincidencias'"
          >
            <div class="product-info" v-for="(shop, i) in mixedarr" :key="i">
              <a class="centeredInfo" :href="'/producto/' + shop.url">
                <!--v-if="shop.categoria == catSelected"-->
                <div class="product-img">
                  <img
                    :src="
                      urlpath.url() +
                      '/P6H_3Pm/bt7-EO4/get-product-image/' +
                      isWebp(regexImg(shop.image), shop.image)
                    "
                    alt="img"
                  />
                </div>
                <p class="titulo-product">{{ shop.name }}</p>
                <p class="price-product">${{ shop.price }}MXN</p>
                <a :href="shop.link" class="btn-comprar">
                  <img src="../assets/carrito-w.svg" alt="cart" class="cart" />
                  <p>COMPRAR</p>
                </a>
              </a>
            </div>
          </div>
          <p class="cnd" v-else>Esta categoría no tiene productos.</p>
        </div>
      </div>
      <!--v-else-if="data.length == 'No se encontraron coincidencias' || data2.length == 'No se encontraron coincidencias'"-->
      <div class="tienda-cont" v-else>
        <p class="cnd">Categoria no disponible.</p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../components/fixedItems/HeaderMenu.vue";
import Footer from "../components/fixedItems/Footer.vue";
import { Carousel, Slide } from "vue-carousel";
let urlpath = require("../global/url");
import { mapActions } from "vuex";
export default {
  components: {
    HeaderMenu,
    Footer,
    Carousel,
    Slide,
  },

  data() {
    return {
      catSelected: 0,
      catName: "",
      catSel: false,
      urlpath,
      idCat: 1,
      categoria: [
        {
          nombre: "Ganado lechero",
        },
        {
          nombre: "Ganado de engorda",
        },
      ],
      products: [
        {
          link: "/producto",
          nombre: "SMART BITES ADULTO 1KG BOVINO",
          seccion: "Vacas",
          precio: 600,
          categoria: "Ganado lechero",
          imagen: require("../assets/sacaje1.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado lechero",
          imagen: require("../assets/sacaje1.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado lechero",
          imagen: require("../assets/sacaje1.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado lechero",
          imagen: require("../assets/sacaje2.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado lechero",
          imagen: require("../assets/sacaje2.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado de engorda",
          imagen: require("../assets/sacaje3.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado de engorda",
          imagen: require("../assets/sacaje3.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado de engorda",
          imagen: require("../assets/sacaje3.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado de engorda",
          imagen: require("../assets/sacaje3.png"),
        },
        {
          link: "",
          nombre: "SMART BITES ADULTO 1KG",
          precio: 600,
          seccion: "Vacas",
          categoria: "Ganado de engorda",
          imagen: require("../assets/sacaje3.png"),
        },
      ],
    };
  },
      metaInfo(){
        return{
            title: 'Tienda - '+this.$route.params.name.toUpperCase(),
            titleTemplate: "%s | Agriga de México",
            htmlAttrs: {
            lang: "es",
            },
            meta: [
            { charset: "utf-8" },
            {
                name: "description",
                content: "Categoría "+this.$route.params.name+" de Agriga de México.",
            },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            ],            
        };
      },
  async created() {
    let option = "product/by/categoryname";
    let name = this.$route.params.name.toUpperCase();
    let payload = {
      option: option,
      name: name,
    };
    await this.findByCategoryNamePro(payload);
    await this.getAllInfoPro2("product");
    await this.getAllInfoPro3("product");
    await this.getAllInfoCat("category");
    await this.getAllInfoGlp('global_params')
  },
  computed: {
    data() {
      let array = this.$store.getters["product/data"];
      let finaldata = []
      for (let k = 0; k < array.length; k++) {
        if (array[k].status == "ACTIVO") {
          finaldata.push(array[k]);
        }
      }
      if(finaldata.length == 0){
        return "No se encontraron coincidencias"
      }else{
      return finaldata;
      }
    },
    data2() {
      let array = this.$store.getters["product/data2"];
      //let array
      let cates = this.cates;
      /**/
      if (cates.length > 0) {
       
        for (let i = 0; i < array.length; i++) {
          if (cates[this.catSelected].name == array[i].category_name) {
            let arr = array.filter(({ category_name }) =>
              category_name.includes(array[i].category_name)
            );
            let farr;
            if (this.wcat == "No se encontraron coincidencias") {
              farr = arr;
            } else {
              farr = arr.concat(this.wcat);
            }
            return farr;
          }
        }
      } else if (this.cates.length == 0) {
        array;
      }
      
      let finaldata = [];
      for (let k = 0; k < array.length; k++) {
        if (array[k].status == "ACTIVO") {
          finaldata.push(array[k]);
        }
      }
      return finaldata;
    },
    data3() {
      let arr = this.$store.getters["product/data3"];
      let finaldata = [];
      for (let k = 0; k < arr.length; k++) {
        if (arr[k].status == "ACTIVO") {
          finaldata.push(arr[k]);
        }
      }
      return finaldata;
    },
    wocat() {
      let arr = this.data3;
      let array = arr.filter(
        (arr) => arr.category_name !== this.$route.params.name.toUpperCase()
      );
      let arr1 = [];
      for (let i = 0; i < array.length; i++) {
        let arr2 = array[i];
        for (let j = 0; j < arr2.product_categories.length; j++) {
          if (
            arr2.product_categories[j].category_name ==
            this.$route.params.name.toUpperCase()
          ) {
            arr1.push(arr2);
          }
        }
      }
      if (arr1.length == 0) {
        return "No se encontraron coincidencias";
      } else {
        return arr1;
      }
    },
    wcat() {
      let arr = this.data3;
      let cates = this.cates;
      if(cates == 'No se encontraron coincidencias'){
        return 'No se encontraron coincidencias'
      }else{
        let array = arr.filter(
          (arr) => arr.category_name !== cates[this.catSelected].name
        );
        let arr1 = [];
        for (let i = 0; i < array.length; i++) {
          let arr2 = array[i];
          for (let j = 0; j < arr2.product_categories.length; j++) {
            if (
              arr2.product_categories[j].category_name ==
              cates[this.catSelected].name
            ) {
              arr1.push(arr2);
            }
          }
        }
        if (arr1.length == 0) {
          return "No se encontraron coincidencias";
        } else {
          return arr1;
        }        
      }

    },
    mixedarr() {
      if (this.wocat == "No se encontraron coincidencias") {
        return this.data;
      } else if (this.data == "No se encontraron coincidencias") {
        return this.wocat;
      } else if (
        this.wocat.length == 0 &&
        this.data == "No se encontraron coincidencias"
      ) {
        return "No se encontraron coincidencias";
      } else {
        return this.data.concat(this.wocat);
      }
    },
    cates() {
      let array = this.$store.getters["category/data"];
      let arr = array.filter(({ parent_name }) =>
        parent_name.includes(this.$route.params.name.toUpperCase())
      );
      if (arr.length == 0) {
        return "No se encontraron coincidencias";
      } else {
        return arr;
      }
    },
    categorias() {
      let array = this.$store.getters["category/data2"];
      return array;
    },
   
   gp() {
       
        let arr = this.$store.getters["global_params/data"]; 
                let filter =[]
        if(!Array.isArray(arr)){
          return []
        }else{  
            filter = arr
        }
        
          return filter


  },
  },
  methods: {
    ...mapActions("product", ["getAllInfoPro2"]),
    ...mapActions("product", ["getAllInfoPro3"]),
    ...mapActions("product", ["findByCategoryNamePro"]),
    ...mapActions("category", ["getAllInfoCat"]),
       ...mapActions('global_params', ['getAllInfoGlp']),

      validText(gp){
       
        if(!gp) return false;
        if(!Array.isArray(gp)) return false;
        
        if(gp.length==0) return false;
        if(!gp[0]?.texto ) return false;
        
      
        if(gp[0]?.texto != ''){
          return true;
        }else {
          return false;
        }
    },
    arrayMethod(products, cat) {
      let arr2 = products.filter(({ id_category }) =>
        id_category.includes(cat)
      );

      return arr2;
    },

    getOption: function (option) {
      this.liOpc = option;
    },
    toCat() {},
    srcImg: function (img) {
      let src = `${urlpath.url()}/Y4k_o1b/lw4-ufj/project-img/${img}`;
      return src;
    },
    regexImg(txt) {
      if(txt){
        let s = txt.split(".");
      return s[0] + ".webp";
      }else{
        return ''
      }
     
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
  },
  /*watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title =
          to.meta.title ||
          `${this.$route.params.name.toUpperCase()} - Agriga de México`;
      },
    },
  },*/
};
</script>
<style>
a,
p {
  font-family: "Helvetica World Regular";
}
.nav-product {
  background: #fe9128;
  display: flex;
  justify-content: center;
  align-items: center;
}
input#checknav {
  display: none;
}
.nav-pro-cont {
  width: 100%;
  padding: 0.729vw 3.49vw;
  display: flex;
  justify-content: space-between;
}
.nav-free-send {
  display: flex;
  align-items: center;
}
.nav-free-send img {
  width: auto;
  height: 1.563vw;
  margin-right: 1.771vw;
}
.nav-free-send p {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 99.5%;
  color: #ffffff;
  margin: 0;
}
.nav-options {
  display: flex;
  align-items: center;
  width: 37.76vw;
  justify-content: space-between;
}
.nav-options a {
  font-weight: 700;
  font-size: 0.781vw;
  line-height: 194.5%;
  color: #ffffff;
  text-decoration-color: transparent;
  transition: 0.5s;
}
.nav-options a:hover {
  text-decoration-color: #ffffff;
}
.add-cart,
.shop-cart {
  width: 9.948vw;
  height: 2.552vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Intro Rust";
  font-weight: 400;
  font-size: 0.521vw;
  line-height: 129.5%;
  transition: 0.5s;
  cursor: pointer;
}
.add-cart {
  color: #fe9128;
  border: 1px solid #fe9128;
  background: white;
}
.add-cart:hover {
  color: white;
  background: #fe9128;
}
.shop-cart {
  color: #fe9128;
  background: white;
  border: 1px solid #fe9128;
}
.shop-cart:hover {
  color: white;
  background: #fe9128;
}
.shop-cart img {
  width: 0.781vw;
  height: 0.781vw;
  padding-right: 0.481vw;
}
.label1 {
  display: none;
}








.header-tienda {
  width: 100%;
  height: 18.385vw;
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 2.344vw;
  line-height: 99.5%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
a.btn-comprar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.813vw;
  height: 2.552vw;
  background: #3ebdb1;
  transition: 0.5s;
  text-decoration-color: transparent;
}
a.btn-comprar:hover {
  background: #2d8e84;
}
a.btn-comprar p {
  color: white;
  font-family: "Intro Rust";
  font-size: 0.521vw;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
img.cart {
  width: 0.781vw;
  height: auto;
  padding-right: 0.4vw;
}
p.cnd {
  margin: 4vw 8vw;
  width: auto;
  font-size: 1vw;
  color: gray;
  text-transform: uppercase;
}
.product-img {
  width: 17.604vw;
  height: 23.542vw;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-info {
  /*width: auto;
    height: auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centeredInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration-color: transparent;
  padding-right: 1.927vw;
  padding-bottom: 1.927vw;
}
p.titulo-product {
  margin: 0 0 0.481vw;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 129.5%;
  text-align: center;
  color: #929292;
  padding-top: 1.563vw;
  transition: 0.5s;
  text-transform: uppercase;
  width: 18.5vw;
}
.product-info:hover p.titulo-product {
  color: #4f4f4f;
}
p.price-product {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 129.5%;
  text-align: center;
  color: #fe9128;
  margin: 0;
  padding-bottom: 1.563vw;
}
.product-img img {
  width: 10.938vw;
  height: auto;
  transition: 0.5s;
}
.product-info:hover .product-img img {
  transform: scale(1.09);
}
.category-header {
  display: flex;
  padding-bottom: 3.125vw;
}
.products-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tienda-cont-el {
  padding: 5.052vw 0;
  margin: 0 5.198vw 0 11.198vw;
}
.category-header p {
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 194.5%;
  color: #bebebe;
  margin: 0;
  padding-right: 1.8vw;
  cursor: pointer;
  transition: 0.5s;
}
.category-header p:hover {
  color: #575757;
}
p.realed {
  font-weight: 700;
  text-decoration-line: underline;
  color: #3ebdb1;
}
.category-header p.realed:hover {
  color: #25756d;
}
@media (max-width: 768px) {
  .nav-pro-cont {
    padding: 2.899vw 7.246vw;
}.label1 {
    display: block;
}.nav-btn {
    display: flex;
    align-items: center;
    border-bottom: 1px solid transparent;
    transition: 0.5s;
}.nav-btn p {
    font-weight: 700;
    font-size: 2.899vw;
    line-height: 194.5%;
    color: #ffffff;
    margin: 0;
    padding-right: 0.8vw;
}.nav-btn img {
    width: 1.932vw;
    height: 1.932vw;
}.nav-options {
    width: 100%;
    z-index: 9;
    position: absolute;
    flex-direction: column;
    background: #fe9128;
    margin-top: 8vw;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}.nav-options a {
    font-weight: 700;
    font-size: 2.781vw;
    line-height: 214.5%;
}
  #checknav:checked ~ .nav-options {
    opacity: 1;
    pointer-events: all;
  }










  p.cnd {
    margin: 18vw 8vw;
    font-size: 3vw;
  }
  .header-tienda {
    height: 50.483vw;
    background-size: 275vw;
    background-position-x: center;
    font-size: 8.454vw;
  }
  .category-header {
    padding-bottom: 7.493vw;
    margin-bottom: 7.493vw;
    overflow-x: scroll;
  }
  .product-img {
    width: 38.647vw;
    height: 51.691vw;
  }
  .product-img img {
    width: 23.913vw;
  }
  p.titulo-product {
    font-size: 2.415vw;
    padding-top: 4.589vw;
    width: 37.5vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p.price-product {
    font-size: 3.14vw;
    padding-bottom: 4.589vw;
  }
  a.btn-comprar {
    width: 38.647vw;
    height: 10.386vw;
  }
  a.btn-comprar p {
    font-size: 2.174vw;
  }
  img.cart {
    width: 2.781vw;
    padding-right: 1.4vw;
  }
  .centeredInfo {
    padding-right: 4.348vw;
    padding-bottom: 4.348vw;
  }
  .tienda-cont-el {
    padding: 12.077vw 0;
    margin: 0 3.198vw 0 6.198vw;
  }
  .category-header p {
    font-size: 3.865vw;
    line-height: 194.5%;
    padding-right: 6.8vw;
  }
}
</style>